<template>
    <modal ref="modalDevolverEntrega" titulo="Devolver Entrega" :desactivar-guardar="false" no-aceptar adicional="Devolver" @adicional="devolver()">
        <div class="row mx-0 j-center">
            <div class="col-10 text-gris f-18 f-600 text-center">
                ¿Estas seguro de devolver la entrega?
            </div>
            <div class="col-10 my-3 text-center">
                El usuario podra cargar nuevamente la entrega para que puedas calificarla
            </div>
        </div>
    </modal>
</template>

<script>
import { entregasHechas } from '~/services/cursos/entregas_hechas';
export default {
    props: {
        id: {
            type: Number,
            default: () => 0,
            required: true
        }
    },
    methods: {
        toggle(){
            this.$refs.modalDevolverEntrega.toggle();
        },
        async devolver(){
            try {
                const { data } = await entregasHechas.devolver(this.id)
                if (data.data){
                    this.notificacion('', 'Entrega devuelta correctamente', 'success')
                    this.$emit('updated')
                    this.toggle()
                }
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style>

</style>